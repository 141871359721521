<template>
  <div class="gg-container" style="padding: 10px 20px">

        <div class="search-container">
          <div class="search-container-fn-input">
            <label>医师姓名：</label>
            <el-input v-model="listQuery.name" placeholder="请输入医师姓名" clearable ></el-input>
          </div>

          <div class="search-container-fn-input">
            <label>日期：</label>
            <el-date-picker
              v-model="date"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="getList()"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>

            <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px;background-color: #2362fb;border-color: #2362fb;margin-left: auto;margin-left:20px;"
            @click="getList()"
          >
            <i  style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">查询</span>
          </el-button>
          </div>

      

          <!-- <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px;background-color: #2362fb;border-color: #2362fb;margin-left: auto;"
            @click="handleExportOrganizationContrast()"
          >
            <i  style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">导出</span>
          </el-button> -->
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            fixed
            label="医生姓名"
            prop="doctor_name"
            align="center"
            width="130"
          >
          </el-table-column>

          <el-table-column label="图文问诊" align="center">
            <el-table-column
              prop="diag_pic_count"
              label="问诊数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_pic_amount"
              label="问诊金额"
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column label="视频问诊" align="center">
            <el-table-column
              prop="diag_video_count"
              label="问诊数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_video_amount"
              label="问诊金额"
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column label="退诊" align="center">
            <el-table-column
              prop="diag_refund_count"
              label="退诊数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_refund_amount"
              label="退诊金额"
              align="center"
            >
            </el-table-column>
          </el-table-column>
         
          <el-table-column
            prop="diag_count"
            label="问诊数"
            align="center"
          >
          </el-table-column>
    
         
          <el-table-column prop="pres_count" label="拍方抓药" align="center">
            <el-table-column
              prop="take_pres_tc_count"
              label="处方数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="take_pres_med_amount"
              label="药材费"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="take_pres_process_amount"
              label="加工费"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="take_pres_tc_amount"
              label="处方金额"
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column prop="pres_count" label="中药处方" align="center">
            <el-table-column
              prop="pres_tc_count"
              label="处方数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="pres_med_amount"
              label="药材费"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="pres_process_amount"
              label="加工费"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="pres_diag_amount"
              label="诊金"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="pres_tc_amount"
              label="处方金额"
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column
            prop="pres_count"
            label="处方数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pres_amount"
            label="处方金额"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="express_fee"
            label="运费"
            align="center"
          >
          </el-table-column>

          <el-table-column prop="pres_count" label="退款处方" align="center">
            <el-table-column
              prop="pres_refund_count"
              label="退款数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="pres_refund_amount"
              label="退款金额"
              align="center"
            >
            </el-table-column>
          </el-table-column>


        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
    

  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getDocReport,
} from "@/api/finance";
export default {
  name: "docReport",
  components: {
    Pagination,
  },
  created() {
    this.getList();
  },
  data() {
    return {
      date:'',
      activeName: "first",
      searchParamsFirst: {
        year: this.$moment().format("YYYY"),
        doctor_id: "",
      },
      tableData:[],
      month: this.$moment().format("YYYY-MM"),
      searchParamsSecond: {
        month: "",
      },
      organizationList: [],
      listLoading: false,
      tableDataFirst: [],
      tableDataSecond: [],
      listQuery: {
        page: 1,
        limit: 50
      },
      doctorName: "",
      doctorList: [],
      total: 0,
    };
  },
  methods: {
    test() {},
    handleClickTabs(tab, event) {
      console.log(tab, event);
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      if(this.date){
        this.listQuery.date = this.date.join(",")
      }
      this.listLoading = true;
      getDocReport(this.listQuery)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.total = data.data.total
            this.tableData = data.data.list;
            this.tableData.push(data.data.total_count)
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
  
    handleExportOrganizationDetail() {},
    handleExportOrganizationContrast() {},
  },
};
</script>

<style scoped>
</style>
